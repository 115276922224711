import { gql } from '@apollo/client'

export const getParticipationTrackingListQuery = gql`
  query getParticipationTrackingList($assignmentId: Float!, $studentId: Float!) {
    getParticipationTrackingList(assignmentId: $assignmentId, studentId: $studentId) {
      student_id
      assignment_id
      date
      status
      comment
      excuse
    }
  }
`

export const getStudentsParticipationById = gql`
  query getStudentsParticipationById($studentIds: [Int!]!, $participationDate: String!) {
    getStudentsParticipationById(studentIds: $studentIds, participationDate: $participationDate) {
      studentId
      assignmentId
      assignmentDueDate
      participationStatus
      comment
      firstName
      lastName
    }
  }
`

export const getParticipationSettingByPersonId = gql`
  query getParticipationSettingByPersonId($personId: Int!) {
    getParticipationSettingByPersonId(personId: $personId) {
      id
      person_id
      notification_type
      created_at
      updated_at
      deleted_at
    }
  }
`

export const getPhoneAvailability = gql`
  query getPhoneAvailabilty($personId: Int!) {
    getPhoneAvailabilty(personId: $personId)
  }
`

export const saveParticipationSettingsMutation = gql`
  mutation createOrUpdateParticipationSettings($personId: Int!, $notificationType: Int!) {
    createOrUpdateParticipationSettings(personId: $personId, notificationType: $notificationType) {
      id
    }
  }
`

export const getParticipationReport = gql`
  query GetParticipationReport(
    $filter: ParticipationReportFilters
    $search: String
    $skip: Int
    $sort: String
    $take: Int
  ) {
    getParticipationReport(filter: $filter, search: $search, skip: $skip, sort: $sort, take: $take) {
      total
      data
    }
  }
`

export const downloadParticipationReport = gql`
  query DownloadParticipationReport(
    $filter: ParticipationReportFilters
    $search: String
    $skip: Int
    $sort: String
    $take: Int
  ) {
    downloadParticipationReport(filter: $filter, search: $search, skip: $skip, sort: $sort, take: $take) {
      data
    }
  }
`
