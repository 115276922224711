import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { KeyboardArrowDown } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import { ClassNameMap, Grid, MenuItem, Select, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import { useFlag } from '@unleash/proxy-client-react'
import { isNil } from 'lodash'
import { CustomConfirmModal } from '@mth/components/CustomConfirmModal/CustomConfirmModal'
import { MthDatePicker } from '@mth/components/MthDatePicker/MthDatePicker'
import {
  SPACE,
  KINDERGARTEN_LABEL,
  KINDERGARTEN_NUM_VALUE,
  PRE_KINDERGARTEN_LABEL,
  PRE_KINDERGARTEN_NUM_VALUE,
  EMPTY_STRING,
  EPIC_2209_STORY_5188,
  WITHDRAWAL_OPTIONS,
  ACTIVATE_OPTIONS,
  INACTIVE_OPTIONS,
  CANCEL,
  EPIC_3322_STORY_5535,
  EPIC_2510_STORY_5661,
  EPIC_3804_BUG_5657,
} from '@mth/constants'
import { DropDown } from '@mth/core/components/shared/DropDown/DropDown'
import { DropDownItem } from '@mth/core/components/shared/DropDown/types'
import { Paragraph } from '@mth/core/components/shared/Paragraph/Paragraph'
import { Subtitle } from '@mth/core/components/shared/Subtitle/Subtitle'
import {
  ActivateOption,
  ApplicationStatus,
  InactiveOption,
  MthColor,
  StatusChangeModalConfirmText,
  StatusChangeModalDescription,
  StatusChangeModalTitle,
  StudentStatus,
  StudentStatusLabel,
  WithdrawalOption,
  WithdrawalStatus,
  datePatterns,
} from '@mth/enums'
import { useDiplomaSeekingOptionsByStudentIdAndSchoolYearId } from '@mth/hooks'
import { Application, SchoolYear, Student } from '@mth/models'
import { UserContext } from '@mth/providers/UserContext/UserProvider'
import { diplomaQuestionDataBySchoolYearGql } from '@mth/screens/Admin/SiteManagement/services'
import {
  calcAge,
  getSoeByYear,
  getStatusText,
  gradeTextByStatus,
  showDate,
  formatDateByPattern,
  utcToTimezoned,
  getTimezone,
  isInEligibleStudentStatus,
  calculateGradeNumber,
  formatUtcToTzByPattern,
} from '@mth/utils'
import { testIDs } from './__tests__/StudentFilters.testIds'
import { StatusChangeModal } from './ChangeStatusModal'
import { BaseSpecialEds, BaseDiplomaSeekingOptions, BaseStatuses } from './defaultValues'
import { selectStyles, useStyles } from './styles'
import { SchoolYearProps } from '../../components/Students/types'
import { WithdrawalByStudent } from '../../types'
import { StudentTemp } from '../types'

type StudentFiltersProps = {
  student: Student
  setStudentStatusData: (value: StudentTemp) => void
  originStudentStatus: StudentStatus
  studentStatusData: StudentTemp
  withdrawalStatus: WithdrawalByStudent
  specialEdOptions: string[]
  setWithdrawalStatus: (value: WithdrawalByStudent | undefined) => void
  setIsChanged: (_: boolean) => void
  schoolYears: SchoolYear[]
  currentSchoolYear: SchoolYearProps
  nextSchoolYear: SchoolYearProps
  schoolYearToShowProp: SchoolYear | SchoolYearProps
}

const getTooltipText = (withdrawalDate: string, deadlineDate: string): string => {
  return `Parent was notified of withdrawal on ${withdrawalDate}. 
          If parent does not sign the withdrawal form by ${deadlineDate}, 
            they will receive an undeclared withdrawal form.`
}

export const StudentFilters: React.FC<StudentFiltersProps> = ({
  student,
  setStudentStatusData,
  originStudentStatus,
  studentStatusData,
  withdrawalStatus,
  specialEdOptions,
  setWithdrawalStatus,
  setIsChanged,
  schoolYears,
  currentSchoolYear,
  nextSchoolYear,
  schoolYearToShowProp,
}) => {
  const { me } = useContext(UserContext)
  const timezone = getTimezone(me!)
  const classes = useStyles
  const selectClasses = selectStyles()
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [isIneligibleState, setIsIneligibleState] = useState<boolean>(false)
  const [application, setApplication] = useState<Application>()
  const [originalStudentStatus, setOriginalStudentStatus] = useState<StudentStatus | null>(null)
  const [, setSpecialEd] = useState<number>()
  const [showWithdrawalModal, setShowWithdrawalModal] = useState<boolean>(false)
  const [showInactiveModal, setShowInactiveModal] = useState<boolean>(false)
  const [showActiveModal, setShowActiveModal] = useState<boolean>(false)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [diplomaSeeking, setDiplomaSeeking] = useState<number | null | undefined>()
  const [editingDOB, setEditingDOB] = useState<boolean>(false)
  const [status, setStatus] = useState<DropDownItem[]>(BaseStatuses)
  const [specialEds, setSpecialEds] = useState<DropDownItem[]>(BaseSpecialEds)
  const DiplomaSeekingOptions: DropDownItem[] = BaseDiplomaSeekingOptions
  const epic2209Story5188 = useFlag(EPIC_2209_STORY_5188)
  const epic3222story5535 = useFlag(EPIC_3322_STORY_5535)
  const epic2510story5661 = useFlag(EPIC_2510_STORY_5661)
  const epic3804bug5657 = useFlag(EPIC_3804_BUG_5657)
  const [diplomaSeekOptionDefaultValue, setDiplomaSeekOptionDefaultValue] = useState<string | undefined>()

  const { diplomaAnswer, diplomaOptions } = useDiplomaSeekingOptionsByStudentIdAndSchoolYearId(
    studentStatusData?.school_year_id || 0,
    studentStatusData?.student_id,
    !studentStatusData?.student_id || !studentStatusData?.school_year_id,
  )

  const activeSchoolYear =
    schoolYears.find((item) => item.school_year_id === Number(currentSchoolYear?.school_year_id)) ||
    schoolYears.find((item) => item.school_year_id === Number(nextSchoolYear?.school_year_id))

  const schoolYearToShowTemp = activeSchoolYear ? activeSchoolYear : currentSchoolYear
  const schoolYearToShow = epic2209Story5188 ? schoolYearToShowProp : schoolYearToShowTemp

  const schoolYearBegin = formatDateByPattern(new Date(schoolYearToShow?.date_begin ?? null), datePatterns.FULL_YEAR)
  const schoolYearEnd = formatDateByPattern(new Date(schoolYearToShow?.date_end ?? null), datePatterns.SHORT_YEAR)

  const { loading, data: diplomaQuestionData } = useQuery(diplomaQuestionDataBySchoolYearGql, {
    variables: {
      diplomaQuestionInput: {
        schoolYearId: studentStatusData?.school_year_id,
      },
    },
    skip: !studentStatusData?.school_year_id,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!loading && diplomaQuestionData) {
      if (diplomaQuestionData?.getDiplomaQuestion) {
        const diplomaGrades = diplomaQuestionData?.getDiplomaQuestion?.grades
        const grades = diplomaGrades?.split(',') ?? []
        const currentGrade = student?.grade_levels?.at(-1)
        setDiplomaSeekOptionDefaultValue(grades.includes(currentGrade) ? '0' : undefined)
      }
    }
  }, [diplomaQuestionData, loading, student])

  const handleChangeStudentStatus = (status: StudentStatus) => {
    if (status === StudentStatus.ACTIVE || status === StudentStatus.PENDING) {
      if (originStudentStatus === StudentStatus.WITHDRAWN) {
        setShowActiveModal(true)
      }
      setOriginalStudentStatus(studentStatusData.status)
      setStudentStatusData({ ...studentStatusData, ...{ status: status } })
    } else if (status === StudentStatus.WITHDRAWN) {
      if (originStudentStatus === StudentStatus.ACTIVE || originStudentStatus === StudentStatus.PENDING) {
        setShowWithdrawalModal(true)
      }
      setOriginalStudentStatus(studentStatusData.status)
      setStudentStatusData({ ...studentStatusData, ...{ status: status } })
    } else if (status === StudentStatus.INACTIVE) {
      setShowInactiveModal(true)
      setOriginalStudentStatus(studentStatusData.status)
      setStudentStatusData({ ...studentStatusData, ...{ status: status } })
    } else {
      setOriginalStudentStatus(status)
      setStudentStatusData({ ...studentStatusData, ...{ status: status } })
    }
  }

  const handleChangeBirthDay = (value: string | null) => {
    if (value) {
      setStudentStatusData({ ...studentStatusData, ...{ birth: value } })
    }
  }

  const handleSelectNewStatusOption = (statusOption: WithdrawalOption | ActivateOption | InactiveOption) => {
    setIsChanged(true)
    if (studentStatusData.status === StudentStatus.WITHDRAWN) {
      setStudentStatusData({ ...studentStatusData, ...{ withdrawOption: statusOption as WithdrawalOption } })
      setShowWithdrawalModal(false)
    } else if (
      studentStatusData.status === StudentStatus.ACTIVE ||
      studentStatusData.status === StudentStatus.PENDING
    ) {
      setStudentStatusData({ ...studentStatusData, ...{ activeOption: statusOption as ActivateOption } })
      setShowActiveModal(false)
    } else if (studentStatusData.status === StudentStatus.INACTIVE) {
      setStudentStatusData({ ...studentStatusData, ...{ inactiveOption: statusOption as InactiveOption } })
      setShowInactiveModal(false)
    }
  }

  const handleStatusChangeCancel = () => {
    if (!isNil(originalStudentStatus))
      setStudentStatusData({ ...studentStatusData, ...{ status: originalStudentStatus } })

    if (studentStatusData.status === StudentStatus.WITHDRAWN) setShowWithdrawalModal(false)
    else if (studentStatusData.status === StudentStatus.ACTIVE || studentStatusData.status === StudentStatus.PENDING)
      setShowActiveModal(false)
    else if (studentStatusData.status === StudentStatus.INACTIVE) setShowInactiveModal(false)
  }

  useEffect(() => {
    if (student) {
      if (epic3804bug5657) {
        const studentApplications = [...(student?.applications || [])]

        const currentSYApplications = studentApplications.filter(
          (item) => item.school_year_id === Number(currentSchoolYear?.school_year_id),
        )

        const sortedApplications =
          currentSYApplications.length > 0
            ? currentSYApplications.sort(
                (a, b) => new Date(b.date_submitted).getTime() - new Date(a.date_submitted).getTime(),
              )
            : studentApplications.sort(
                (a, b) => new Date(b.date_submitted).getTime() - new Date(a.date_submitted).getTime(),
              )

        setApplication(sortedApplications[0])
      } else {
        setApplication(
          student?.applications?.find((item) => item.school_year_id === Number(currentSchoolYear?.school_year_id)) ||
            student?.applications?.at(-1),
        )
      }
    }
    if (!epic2209Story5188) {
      const grades = schoolYearToShow?.grades
        ?.replace(PRE_KINDERGARTEN_LABEL, PRE_KINDERGARTEN_NUM_VALUE.toString())
        ?.replace(KINDERGARTEN_LABEL, KINDERGARTEN_NUM_VALUE.toString())
        ?.split(',')

      const studentGradeLevel = student?.grade_levels?.at(-1)
      const currentGrade = calculateGradeNumber(
        studentGradeLevel?.grade_level,
        studentGradeLevel?.school_year?.date_begin,
        schoolYearToShow?.date_begin,
      )
      if (grades) setIsIneligibleState(!grades?.includes(currentGrade.toString()))
    }
  }, [student, currentSchoolYear, schoolYearToShow])

  const isIneligible2 = useMemo(() => {
    const studentGradeLevel = student?.grade_levels?.at(-1)
    return isInEligibleStudentStatus(schoolYearToShow?.grades, studentGradeLevel, schoolYearToShow?.date_begin)
  }, [student, schoolYearToShow])

  const isIneligible = epic2209Story5188 ? isIneligible2 : isIneligibleState

  useEffect(() => {
    if (specialEdOptions.length !== 0) {
      const specialEdDropdowns: DropDownItem[] = []
      specialEdOptions.map((item, index): void => {
        specialEdDropdowns.push({
          label: item,
          value: index,
        })
      })
      setSpecialEds(specialEdDropdowns)
    }
  }, [specialEdOptions])

  useEffect(() => {
    if (studentStatusData?.date) {
      const updatedStatus = BaseStatuses.map((item) => {
        if (
          studentStatusData?.status === item.value ||
          (studentStatusData?.status === StudentStatus.REAPPLIED && item.value === StudentStatus.APPLIED)
        ) {
          return {
            ...item,
            label: `${
              studentStatusData?.status === StudentStatus.REAPPLIED ? 'Applied (re-apply)' : item.label
            } ${formatDateByPattern(new Date(studentStatusData?.date), datePatterns.ZMONTH_ZDAY_4DYEAR)}`,
            value: studentStatusData.status === StudentStatus.REAPPLIED ? StudentStatus.REAPPLIED : item.value,
          }
        }
        return item
      })
      setStatus(updatedStatus)
    }

    if (
      studentStatusData.status === StudentStatus.WITHDRAWN &&
      studentStatusData.withdrawOption &&
      studentStatusData.withdrawOption > 0
    ) {
      const updatedStatus = status.map((status) => {
        if (status.value === StudentStatus.WITHDRAWN) {
          return { ...status, label: `Withdrawn (${formatDateByPattern(new Date(), datePatterns.ZMONTH_ZDAY_4DYEAR)})` }
        }
        return status
      })
      setStatus(updatedStatus)
    }

    if (studentStatusData.status === StudentStatus.ACTIVE && studentStatusData.activeOption) {
      const updatedStatus = status.map((status) => {
        if (status.value === StudentStatus.ACTIVE) {
          return { ...status, label: `Active (${formatDateByPattern(new Date(), datePatterns.ZMONTH_ZDAY_4DYEAR)})` }
        }
        return status
      })
      setStatus(updatedStatus)
    }

    if (
      studentStatusData.status === StudentStatus.INACTIVE &&
      studentStatusData.inactiveOption &&
      studentStatusData.inactiveOption > 0
    ) {
      const updatedStatus = status.map((status) => {
        if (status.value === StudentStatus.INACTIVE) {
          return { ...status, label: `Inactive (${formatDateByPattern(new Date(), datePatterns.ZMONTH_ZDAY_4DYEAR)})` }
        }
        return status
      })
      setStatus(updatedStatus)
    }

    if ([StudentStatus.COMPLETED, StudentStatus.GRADUATED].includes(studentStatusData.status)) {
      setStatus([
        {
          label: `${
            studentStatusData.status === StudentStatus.COMPLETED
              ? StudentStatusLabel.COMPLETED
              : StudentStatusLabel.GRADUATED
          } (${formatDateByPattern(new Date(studentStatusData?.date), datePatterns.ZMONTH_ZDAY_4DYEAR)})`,
          value: studentStatusData.status,
        },
      ])
    }
  }, [studentStatusData])

  const onRemoveWithdrawalRequest = async () => {
    setShowConfirmModal(true)
  }

  const getSelectClassName = (
    studentStatusData: StudentTemp,
    withdrawalStatus: string,
    selectClasses: ClassNameMap,
  ) => {
    const isWithdrawn = studentStatusData?.status === StudentStatus.WITHDRAWN
    const isRequested = withdrawalStatus === StudentStatusLabel.REQUESTED

    if (isWithdrawn && isRequested) {
      return selectClasses.yellowBackgroundSelect
    } else if (isWithdrawn) {
      return selectClasses.withdrawBackgroundSelect
    } else if (isRequested) {
      return selectClasses.yellowBackgroundSelect
    } else {
      return selectClasses.backgroundSelect
    }
  }

  useEffect(() => {
    if (diplomaOptions && schoolYearToShow?.diploma_seeking) {
      setDiplomaSeeking(diplomaAnswer)
    }
  }, [diplomaOptions, diplomaAnswer])

  const subtitleText = useMemo(() => {
    if (!isIneligible && application && schoolYearToShow) {
      if (application.school_year_id === schoolYearToShow?.school_year_id) {
        return `${schoolYearBegin} - ${schoolYearEnd} ${application.midyear_application ? 'Mid-year Status' : 'Status'}`
      } else {
        return `${schoolYearBegin} - ${schoolYearEnd} Status`
      }
    }
    return 'Status'
  }, [isIneligible, application, schoolYearToShow])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
      }}
    >
      <Grid container>
        <Grid item xs={4} display={'flex'} flexDirection={'column'} textAlign='left' sx={{ paddingRight: '16px' }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Subtitle fontWeight='700' sx={{ marginRight: '16px', marginBottom: '5px' }}>
              {subtitleText}
            </Subtitle>
            <Box>
              <Box display={'flex'}>
                <Select
                  className={
                    isIneligible
                      ? selectClasses.backgroundSelect
                      : getSelectClassName(studentStatusData, withdrawalStatus?.status, selectClasses)
                  }
                  IconComponent={KeyboardArrowDown}
                  inputProps={{
                    classes: {
                      icon: selectClasses.selectIcon,
                    },
                  }}
                  value={isIneligible ? StudentStatus.INELIGIBLE : Number(studentStatusData.status)}
                  onChange={(e) => {
                    if (!isIneligible) {
                      handleChangeStudentStatus(e.target.value as StudentStatus)
                    }
                  }}
                >
                  {isIneligible ? (
                    <MenuItem
                      key={StudentStatus.INELIGIBLE}
                      value={StudentStatus.INELIGIBLE}
                      sx={{ height: '35px' }}
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      {StudentStatusLabel.INELIGIBLE}
                    </MenuItem>
                  ) : (
                    status.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        sx={{ height: '35px' }}
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    ))
                  )}
                </Select>

                {withdrawalStatus?.status === WithdrawalStatus.NOTIFIED &&
                  studentStatusData?.status === StudentStatus.WITHDRAWN &&
                  epic2510story5661 && (
                    <Tooltip
                      title={getTooltipText(
                        formatUtcToTzByPattern(withdrawalStatus?.date, timezone, datePatterns.ZMONTH_ZDAY_4DYEAR),
                        formatUtcToTzByPattern(withdrawalStatus?.deadline, timezone, datePatterns.ZMONTH_ZDAY_4DYEAR),
                      )}
                      placement='top'
                    >
                      <InfoIcon sx={{ cursor: 'pointer', width: '40px', marginTop: '2px' }} fontSize='medium' />
                    </Tooltip>
                  )}
              </Box>

              {withdrawalStatus?.status === WithdrawalStatus.REQUESTED && (
                <Box onClick={() => onRemoveWithdrawalRequest()}>
                  <Paragraph sx={{ color: MthColor.MTHBLUE, my: '5px', cursor: 'pointer' }} textAlign='center'>
                    Remove Withdraw Request
                  </Paragraph>
                </Box>
              )}
            </Box>
          </Box>
          <Box onClick={() => setShowDetails(!showDetails)}>
            <Paragraph sx={{ textDecoration: 'underline', color: MthColor.MTHBLUE, cursor: 'pointer' }}>
              {showDetails ? 'Hide' : 'View'} Details
            </Paragraph>
          </Box>
          {showWithdrawalModal && (
            <StatusChangeModal
              title={StatusChangeModalTitle.WITHDRAW}
              description={StatusChangeModalDescription.WITHDRAW}
              options={WITHDRAWAL_OPTIONS}
              confirmStr={StatusChangeModalConfirmText.WITHDRAW}
              cancelStr={CANCEL}
              onStatusChange={handleSelectNewStatusOption}
              onClose={() => handleStatusChangeCancel()}
            />
          )}
          {showActiveModal && (
            <StatusChangeModal
              title={StatusChangeModalTitle.ACTIVE}
              description={StatusChangeModalDescription.ACTIVE}
              options={ACTIVATE_OPTIONS}
              confirmStr={StatusChangeModalConfirmText.ACTIVE}
              cancelStr={CANCEL}
              onStatusChange={handleSelectNewStatusOption}
              onClose={() => handleStatusChangeCancel()}
            />
          )}
          {showInactiveModal && epic3222story5535 && (
            <StatusChangeModal
              title={StatusChangeModalTitle.INACTIVE}
              description={StatusChangeModalDescription.INACTIVE}
              options={INACTIVE_OPTIONS}
              confirmStr={StatusChangeModalConfirmText.INACTIVE}
              cancelStr={CANCEL}
              onStatusChange={handleSelectNewStatusOption}
              onClose={() => handleStatusChangeCancel()}
            />
          )}
          {showConfirmModal && (
            <CustomConfirmModal
              header='Remove Request'
              content='Are you sure you want to remove this Withdraw Request?'
              confirmBtnTitle='Delete'
              handleConfirmModalChange={(isOk: boolean) => {
                if (isOk) {
                  setWithdrawalStatus(undefined)
                  setStudentStatusData({
                    ...studentStatusData,
                    ...{ activeOption: ActivateOption.DELETE_WITHDRAWAL_FORM },
                  })
                }
                setShowConfirmModal(false)
              }}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Subtitle textAlign='left' fontWeight='700' sx={{ marginRight: '30px', marginBottom: '5px' }}>
              Special Ed
            </Subtitle>
            <Select
              className={selectClasses.backgroundSelect}
              IconComponent={KeyboardArrowDown}
              inputProps={{
                classes: {
                  icon: selectClasses.selectIcon,
                },
              }}
              value={Number(studentStatusData.special_ed ?? 0)}
              onChange={(e) => {
                setSpecialEd(Number(e.target.value))
                setStudentStatusData({ ...studentStatusData, ...{ special_ed: Number(e.target.value) } })
              }}
            >
              {specialEds.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Subtitle textAlign='left' fontWeight='700' sx={{ marginRight: '30px', marginBottom: '5px' }}>
              Diploma Seeking
            </Subtitle>
            <DropDown
              sx={{
                padding: '0px 20px 4px 10px',
                background: MthColor.BUTTON_LINEAR_GRADIENT,
                height: '29px',
                borderRadius: '8px',
                textAlign: 'center',
                '& .MuiSelect-select': {
                  color: MthColor.WHITE,
                  fontWeight: 500,
                  fontSize: '14px',
                },
                '& .MuiSvgIcon-root': {
                  color: `${MthColor.WHITE} !important`,
                },
              }}
              testId={testIDs.diplomaSeekingDropdown}
              dropDownItems={DiplomaSeekingOptions}
              defaultValue={`${diplomaSeeking ?? diplomaSeekOptionDefaultValue ?? EMPTY_STRING}`}
              borderNone={true}
              setParentValue={(val) => {
                setDiplomaSeeking(isNaN(Number(val)) ? null : Number(val))
                setStudentStatusData({
                  ...studentStatusData,
                  ...{ diploma_seeking: isNaN(Number(val)) ? undefined : Number(val) },
                })
              }}
            />
          </Box>
        </Grid>
        {showDetails && (
          <Grid item xs={12}>
            <Box sx={classes.content}>
              <Box sx={classes.formRow}>
                <Subtitle sx={classes.formLabel} fontWeight='500'>
                  Application
                  <Box sx={classes.labelAfter}></Box>
                </Subtitle>
                <Subtitle sx={{ ...classes.formValue }} fontWeight='500'>
                  {application?.status}
                  {SPACE}
                  {application?.status === ApplicationStatus.ACCEPTED
                    ? formatDateByPattern(
                        utcToTimezoned(application.date_accepted, timezone),
                        datePatterns.ZMONTH_ZDAY_4DYEAR,
                      )
                    : formatDateByPattern(
                        application?.date_submitted ? utcToTimezoned(application.date_submitted, timezone) : new Date(),
                        datePatterns.ZMONTH_ZDAY_4DYEAR,
                      )}
                </Subtitle>
              </Box>
              <Box sx={classes.formRow}>
                <Subtitle sx={classes.formLabel} fontWeight='500'>
                  Date of Birth
                  <Box sx={classes.labelAfter}></Box>
                </Subtitle>
                {!editingDOB ? (
                  <Subtitle
                    sx={{ ...classes.formValue, cursor: 'pointer' }}
                    fontWeight='500'
                    onClick={() => setEditingDOB(true)}
                  >
                    {showDate(studentStatusData?.birth)}
                    {studentStatusData?.birth && ` (${calcAge(studentStatusData?.birth)})`}
                  </Subtitle>
                ) : (
                  <Box sx={{ paddingX: '30px' }}>
                    <MthDatePicker
                      date={
                        studentStatusData?.birth?.includes('T')
                          ? studentStatusData?.birth?.split('T')[0]
                          : studentStatusData?.birth
                      }
                      label={'Date Of Birth'}
                      handleChange={handleChangeBirthDay}
                    />
                  </Box>
                )}
              </Box>
              {schoolYears.map((schoolYearItem, idx) => (
                <Box sx={classes.formRow} key={idx}>
                  <Subtitle sx={classes.formLabel} fontWeight='500'>
                    {`${formatDateByPattern(
                      new Date(schoolYearItem.date_begin),
                      datePatterns.FULL_YEAR,
                    )}-${formatDateByPattern(new Date(schoolYearItem.date_end), datePatterns.SHORT_YEAR)} ${
                      schoolYearItem.midyear_application ? 'Mid-year' : ''
                    }`}
                    <Box sx={classes.labelAfter}></Box>
                  </Subtitle>
                  <Box sx={classes.formRow}>
                    <Subtitle sx={classes.formValue} fontWeight='500'>
                      {gradeTextByStatus(student, schoolYearItem.school_year_id)}
                      <Box sx={classes.labelAfter}></Box>
                    </Subtitle>
                  </Box>
                  <Box sx={classes.formRow}>
                    <Subtitle sx={classes.formValue} fontWeight='500'>
                      {getStatusText(student, schoolYearItem.school_year_id)}
                      <Box sx={classes.labelAfter}></Box>
                    </Subtitle>
                  </Box>
                  <Box sx={classes.formRow}>
                    <Subtitle sx={classes.formValue} fontWeight='500'>
                      {getSoeByYear(student, schoolYearItem.school_year_id)}
                      <Box sx={classes.labelAfter}></Box>
                    </Subtitle>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
