import { gql } from '@apollo/client'

export const GetAssignmentById = gql`
  query GetAssignmentById($assignmentId: Int!) {
    getAssignmentById(assignmentId: $assignmentId) {
      Master {
        school_year_id
      }
      title
      id
    }
  }
`

export const GetAssignmentReport = gql`
  query GetAssignmentReport($assignmentLearningLogsReportInput: AssignmentLearningLogsReportInput!) {
    getAssignmentReport(assignmentLearningLogsReportInput: $assignmentLearningLogsReportInput) {
      data {
        created_at
        grade
        id
        level
        parentFullName
        status
        studentFullName
        updated_at
        graded_at
        timezone
        studentFirstName
        studentLastName
        studentPreferredFirstName
        studentPreferredLastName
      }
      learningLog {
        id
        title
      }
      pageTotal
      total
    }
  }
`

export const GetTeachersByUserIdQuery = gql`
  query GetTeachersByUserId($userId: Int!) {
    getTeachersByUserId(userId: $userId) {
      results
    }
  }
`

export const getTeacherAssignmentsReportQuery = gql`
  query GetTeacherAssignmentsReport(
    $filter: TeacherAssignmentFilter
    $skip: Int
    $search: String
    $take: Int
    $sortBy: SortInput!
  ) {
    getTeacherAssignmentsReport(filter: $filter, skip: $skip, search: $search, take: $take, sortBy: $sortBy) {
      results {
        assignmentId
        dueDate
        teacherDeadline
        title
        gradedCount
        ungradedCount
        notSubmittedCount
        timezone
      }
      total
    }
  }
`

export const GetClassByIdQuery = gql`
  query GetClassById($classId: Int!) {
    getClassById(classId: $classId) {
      class_id
      master_id
      class_name
    }
  }
`
