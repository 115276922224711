import { useState, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { omit } from 'lodash'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { EnrollmentPacketSaveAction, EnrollmentPacketSaveContext } from '@mth/enums'
import { Packet, Parent } from '@mth/models'
import {
  savePacketMutation,
  updateCreateStudentImmunizationMutation,
} from '@mth/screens/Admin/EnrollmentPackets/services'
import { EnrollmentQuestionTab } from '@mth/screens/Admin/SiteManagement/EnrollmentSetting/EnrollmentQuestions/types'

interface Immunization {
  student_id: string
  immunization_id: string
  value: string
  school_year_id: number
}
interface formValues {
  preSaveStatus?: string
  notes?: string
  age_issue?: boolean
  exemptionDate?: string
  medicalExempt?: string
  last_clicked_action?: string
  parent?: Parent
  immunizations: Immunization[]
}

export const useSubmitEnrollmentPacket = (
  packet: Packet | undefined,
  refetch: () => void,
  methods: UseFormReturn<FieldValues>,
  questionsData: EnrollmentQuestionTab[] | undefined,
  scheduleEnabled: boolean | undefined,
): { onSubmit: (vals: formValues) => void; loading: boolean; error: string } => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const [updateCreateStudentImm] = useMutation(updateCreateStudentImmunizationMutation)
  const [savePacket] = useMutation(savePacketMutation, {
    fetchPolicy: 'network-only',
  })

  const onSubmit = useCallback(
    async (vals) => {
      setLoading(true)
      setError('')
      try {
        const status = vals.preSaveStatus
        const packetId = packet?.packet_id
        const metaField = packet?.meta
        const schoolYearId = packet?.school_year_id
        const student = packet?.student
        const studentPerson = student?.person
        const parent = student?.parent

        const temp = {
          packet: {},
          student: {
            first_name: studentPerson?.first_name,
            last_name: studentPerson?.last_name,
            address: {},
          },
          parent: { ...omit(parent?.person, ['person_id', 'address', 'emailConfirm']) },
          meta: {},
          packet_id: Number(packetId),
          admin_notes: vals.notes,
          status,
          exemption_form_date: vals.exemptionDate,
          medical_exemption: vals.medicalExempt ? 1 : 0,
          missing_files: status === EnrollmentPacketSaveAction.MISSING_INFO ? JSON.stringify(vals.missing_files) : '',
          last_clicked_action: vals.last_clicked_action,
        }

        if (questionsData && questionsData?.length > 0) {
          questionsData.map((tab) => {
            tab?.groups?.map((group) => {
              group?.questions?.map((q) => {
                // if(q.display_admin) {
                if (q.default_question) {
                  if (q.slug.includes('packet_')) {
                    const fieldName = q.slug.split('packet_')[1]
                    // @ts-ignore
                    temp.packet[fieldName] = vals[q.slug]
                  } else if (q.slug.includes('student_')) {
                    const fieldName = q.slug.split('student_')[1]
                    if (fieldName !== 'student_emailConfirm') {
                      // @ts-ignore
                      temp.student[fieldName] = vals[q.slug]
                    }
                  } else if (q.slug.includes('address_')) {
                    const fieldName = q.slug.split('address_')[1]
                    // @ts-ignore
                    temp.student.address[fieldName] = vals[q.slug]
                  } else if (q.slug.includes('parent_')) {
                    const fieldName = q.slug.split('parent_')[1]
                    if (fieldName !== 'parent_emailConfirm') {
                      // @ts-ignore
                      temp.parent[fieldName] = vals[q.slug]
                    }
                  } else if (q.slug == 'meta_special_education') {
                    // @ts-ignore
                    temp.meta[q.slug] = Number(vals[q.slug])
                  } else {
                    // @ts-ignore
                    temp.meta[q.slug] = vals[q.slug]
                  }
                } else {
                  // @ts-ignore
                  temp.meta[q.slug] = vals[q.slug]
                }
                // }
              })
            })
          })
        }

        const parentLegalName =
          metaField && JSON.parse(metaField)?.meta_parentlegalname ? JSON.parse(metaField).meta_parentlegalname : ''
        // @ts-ignore
        temp.meta['meta_parentlegalname'] = parentLegalName

        if (!temp.parent['email']) {
          temp.parent['email'] = vals.parent.user.email
        }

        await updateCreateStudentImm({
          variables: {
            input: vals.immunizations.map((v: Immunization) => ({
              student_id: v.student_id,
              immunization_id: v.immunization_id,
              value: v.value,
              school_year_id: schoolYearId,
            })),
          },
        })

        await savePacket({
          variables: {
            enrollmentPacketInput: {
              ...temp,
              student: omit(temp.student, ['emailConfirm']),
              parent: omit(temp.parent, ['emailConfirm', 'user']),
              meta: JSON.stringify(temp.meta),
              save_context: EnrollmentPacketSaveContext.PACKET_ACTION,
              packet_id: Number(packetId),
            },
          },
        })

        refetch()
        if (status === EnrollmentPacketSaveAction.ACCEPTED) {
          methods.setValue('saveAlert', 'The packet has been accepted')
          setTimeout(() => methods.setValue('saveAlert', ''), 5000)
        } else {
          methods.setValue('saveAlert', 'Packet Saved')
          setTimeout(() => methods.setValue('saveAlert', ''), 5000)
        }
      } catch (error) {
        methods.setValue('saveError', 'Error while updating packet')
        setTimeout(() => methods.setValue('saveError', ''), 5000)
        setError(String(error))
        console.error('Submission failed', error)
      } finally {
        setLoading(false)
      }
    },
    [packet, questionsData, scheduleEnabled],
  )

  return {
    onSubmit,
    loading,
    error,
  }
}
