import React from 'react'
import { Typography } from '@mui/material'
import { useFlag } from '@unleash/proxy-client-react'
import DOMPurify from 'dompurify'
import { EMPTY_STRING, EPIC_121_STORY_5583 } from '@mth/constants'
import { MthColor, TagType } from '@mth/enums'
import { DisplayHTMLProps } from './DisplayHTML.types'

export const DisplayHTML: React.FC<DisplayHTMLProps> = ({
  text = EMPTY_STRING,
  dataTestId = EMPTY_STRING,
  tagType = TagType.DIV,
  allowIframe = false,
  removeMarginBlock = false,
}) => {
  const epic121story5583 = useFlag(EPIC_121_STORY_5583)
  const sanitize = (text: string, allowIframe: boolean) => {
    if (allowIframe)
      return DOMPurify.sanitize(text, {
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['frameborder', 'target'],
      })

    return DOMPurify.sanitize(text, {
      ADD_ATTR: ['target'],
    })
  }

  return (
    <Typography
      component={tagType === TagType.PARAGRAPH ? 'p' : 'span'}
      data-testid={dataTestId}
      dangerouslySetInnerHTML={{
        __html: sanitize(text, allowIframe),
      }}
      sx={{
        ...(epic121story5583 ? { a: { color: MthColor.LL_LINK } } : {}),
        ...(removeMarginBlock
          ? {
              '> p': {
                marginBlockStart: 0,
              },
            }
          : {}),
      }}
    />
  )
}
