export enum EmailCategoryEnum {
  USERS = 'Users',
  APPLICATIONS = 'Applications',
  PACKETS = 'Enrollment Packets',
  SCHEDULES = 'Schedules',
  WITHDRAWAL = 'Withdrawal',
  DIRECTORDERS = 'Direct Orders',
  REIMBURSEMENTS = 'Reimbursements',
  HOMEROOM_RESOURCES = 'Homeroom Resources',
  INTENT_TO_REENROLL = 'Intent to Re-enroll',
  HOMEROOM = 'Homeroom',
  PARTICIPATION = 'Participation',
}

export enum EmailTemplateEnum {
  APPLICATION_PAGE = 'Application Page',
  AGE_ISSUE = 'Age Issue',
  SCHEDULE_ACCEPTED = 'Accepted',
  SECOND_SEMESTER_ACCEPTED = '2nd Semester Accepted',
  SECOND_SEMESTER_UNLOCKED = '2nd Semester Unlocked',
  APPLICATION_ACCEPTED = 'Application Accepted',
  APPLICATION_RECEIVED = 'Application Received',
  EMAIL_CHANGED = 'Email Changed',
  EMAIL_VERIFICATION = 'Email Verification',
  ENROLLMENT_PACKET_PAGE = 'Enrollment Packet Page',
  FORGOT_PASSWORD = 'Forgot Password',
  MISSING_INFORMATION = 'Missing Information',
  NOTIFY_OF_WITHDRAW = 'Notify of Withdraw',
  PACKET_ACCEPTED = 'Packet Accepted',
  PACKET_REMINDERS = 'Packet Reminders',
  UNDECLARED_WITHDRAW = 'Undeclared Withdraw',
  UPDATES_ALLOWED = 'Updates Allowed',
  UPDATES_REQUIRED = 'Updates Required',
  WITHDRAW_CONFIRMATION = 'Withdraw Confirmation',
  WITHDRAW_PAGE = 'Withdraw Page',
  HOMEROOM_RESOURCES_ACCEPTED = 'Homeroom Resource Accepted',
  REMINDERS = 'Reminder',
  HOMEROOM_REMINDERS = 'Homeroom Reminder',
  AUTO_GRADE = 'Auto-grade',
  HOMEROOM_AUTO_GRADE = 'Homeroom Auto-grade',
  RESUBMIT_REQUIRED = 'Resubmit Required',
  INTENTO_TO_REENROLL_REMINDER = 'Intent to Re-enroll Reminder',

  // Reimbursement Emails
  RB_UPDATES_REQUIRED = 'Reimbursement Updates Required',
  RB_APPROVED = 'Reimbursement Approved',
  RB_PAID = 'Reimbursement Paid',
  DO_UPDATES_REQUIRED = 'Direct Order Updates Required',
  DO_APPROVED = 'Direct Order Approved',
  DO_ORDERED = 'Direct Order Ordered',

  DEADLINE = 'deadline',
  CUSTOM_DEADLINE = 'custom_deadline',

  //Participation
  FIRST_REMINDER = 'First Reminder',
  SECOND_REMINDER = 'Second Reminder',
  TEXT_MESSAGE = 'Text Message',
}

export enum EmailTemplateDescription {
  RESOURCE = 'Title of Resource',
  USERNAME = 'User Name for Account',
  PASSWORD = 'Password for Account',
  PARENT_EMAIL = 'Parent Email',
  STUDENT_EMAIL = 'Student Email',
  CONFIRMATION = 'Confirmation Number(s)',
  ADMIN_FULL_NAME = 'User’s First and Last Name that orders the request',
  ADMIN_USER_EMAIL = 'User’s email that orders the request',
  DEADLINE = 'Due Date',
  PARTICIPATION_LINK = 'Link to Parent’s Participation Page',
}

export enum EmailType {
  MISSING_INFO = 'missingInfo',
  AGE_ISSUE = 'ageIssue',
}

export enum EmailVerificationType {
  NEW_ACCOUNT = 0,
  EMAIL_CHANGED = 1,
}

export enum DaysToDisplayType {
  NINETY = 90,
}

export enum TemplateType {
  ALL = 'All',
}
